import React from 'react'
import { graphql } from "gatsby"

import Header from '../components/Template/Header'
//import Newsletter from '../components/Template/Newsletter'
import Footer from '../components/Template/Footer'

import bootstrap from '../css/bootstrap.min.module.css'
import styles from '../css/blog.module.css'
//import custom from '../css/blog.custom.module.css'


export default class Home extends React.Component {

RecentPosts = (props) => 
(
	<div className={styles.siteSection}>
      <div className={bootstrap.container}>
        <div className={`${bootstrap.row} ${bootstrap.mb5}`}>
          <div className={bootstrap.col12}>
            <h2>Recent Posts</h2>
          </div>
        </div>

        <div className={bootstrap.row}>
        { props.posts.map((x, i) => 
        	(
    		  <div className={`${bootstrap.colLg4} ${bootstrap.mb4}`}>
	            <div className={styles.entry2}>
	              <a href={"/blog" + x.node.fields.slug}><img src={x.node.frontmatter.featured_image} alt={x.node.frontmatter.title} className={`${bootstrap.imgFluid} ${bootstrap.rounded}`}/></a>
	              <div className={styles.excerpt}>
	              {/*<span className={`${styles.postCategory} ${bootstrap.textWhite} ${bootstrap.bgSecondary} ${bootstrap.mb3}`}>Politics</span>
                */}
	              <h2><a href={"/blog" + x.node.fields.slug}>{x.node.frontmatter.title}</a></h2>
	              <div className={`${styles.postMeta} ${bootstrap.alignItemsCenter} ${bootstrap.textLeft} ${bootstrap.clearfix}`}>
	                <figure className={`${styles.authorFigure} ${bootstrap.mb0} ${bootstrap.mr3} ${bootstrap.floatLeft}`}>
                  <img src={x.node.frontmatter.author_avatar} alt="author" className={bootstrap.imgFluid}/></figure>
	                
	                <span className={`${bootstrap.mt1}`} ref={element => { 
                       if (element) element.style.setProperty('marginTop', '0px', 'important'); 
                     }}
                  >
                  By <a href="/#">{x.node.frontmatter.author}</a>
                  </span>
                  <span>&nbsp;-&nbsp; {x.node.frontmatter.date}</span>
	              </div>
	              
	                <p>{x.node.excerpt}</p>
	                <p><a href={"/blog" + x.node.fields.slug}>Read More</a></p>
	              </div>
	            </div>
	          </div>
        	)
          )	
        }		

        </div>

        <div className={`${bootstrap.row} ${bootstrap.textCenter} ${bootstrap.pt5} ${styles.borderTop}`}>
          <div className={bootstrap.colMd12}>
            <div className={styles.customPagination}>
              	{	
              
            	this.props.pageContext.currentPage === 1 ?
            		<><span>1</span>
              		<a href="/blog/2">2</a></>
              	:	
            		<>
            		<a href={"/blog/" + (this.props.pageContext.currentPage - 1)}>{this.props.pageContext.currentPage - 1}</a>
            		<span>{this.props.pageContext.currentPage}</span>
            		{this.props.pageContext.currentPage === this.props.pageContext.numPages ?
            			null
            			:
            			<a href={"/blog/" + (this.props.pageContext.currentPage + 1)}>{this.props.pageContext.currentPage + 1}</a>
            		}
            		</>	
              		
             	}
            </div>
          </div>
        </div>

      </div>
    </div>
)

render() {
const posts = this.props.data.allMarkdownRemark.edges

return(
	<>
	<Header/>
	<this.RecentPosts posts={posts}/>
	{/*<Newsletter/>*/}
    <Footer/>
    </>
)
}

}

export const ListQuery = graphql`
  query ListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
    ) {
	     edges {
	      node {
	        frontmatter {
	          author
	          author_avatar
	          date
	          title
	          featured_image
	        }
	        fields {
	          slug
	        }
	        excerpt
	      }
	    }
	 }
  }`